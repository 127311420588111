<template>
  <div>
    <v-layout class="p-4 pr-0 border-bottom-light-grey min-height-57px">
      <v-flex class="d-flex font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <!--  <v-icon color="blue darken-4">mdi-cart</v-icon> -->
          <span class="svg-icon svg-icon-lg mr-2 svg-v-list-icon">
            <inline-svg :src="$assetURL('media/custom-svg/pricing.svg')" />
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="form-title font-size-20 font-weight-700">Price</span>
        <!-- <pre>{{priceData}}</pre> -->
      </v-flex>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        v-if="productStatus != 3"
        @click="savePriceDialog = true"
        color="cyan white--text"
        dense
        tile
        >Manage Price</v-btn
      >
    </v-layout>
    <div
      class="overflow-y"
      style="max-height: calc(100vh - 306px)"
      v-if="!pageLoading"
    >
      <template v-if="priceData.length">
        <table width="100%" class="detail-table table-head-sticky">
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <th
                width="50"
                class="py-1 font-size-13 text-uppercase font-weight-500"
              >
                #
              </th>
              <!-- <th class="p-2 blue lighten-4 text-left" width="200">Unit Price</th> -->
              <th class="py-1 font-size-13 text-uppercase font-weight-500">
                Selling Price/Unit Price
              </th>
              <!--  <th class="py-1 font-size-13 text-uppercase font-weight-500">
                Discounted Price
              </th> -->
              <th class="py-1 font-size-13 text-uppercase font-weight-500">
                Start Date
              </th>
              <th class="py-1 font-size-13 text-uppercase font-weight-500">
                End Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in priceData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <td class="p-2 border-top-light-grey text-center">
                {{ index + 1 }}
              </td>

              <td class="p-2 border-top-light-grey">
                {{ formatMoney(row.sale_price) }}
              </td>
              <!--  <td class="p-2 border-top-light-grey">
                {{ formatMoney(row.discount_price) }}
              </td> -->
              <td class="p-2 border-top-light-grey">
                <template v-if="row.start_date">
                  {{ formatDate(row.start_date) }}
                </template>
                <template v-else>
                  <em class="text-muted">no start date</em>
                </template>
              </td>
              <td class="p-2 border-top-light-grey">
                <template v-if="row.end_date">
                  {{ formatDate(row.end_date) }}
                </template>
                <template v-else>
                  <em class="text-muted">no end date</em>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <p v-else class="m-0 row-not-found text-center">
        <img
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image"
        />
        Uhh... There are no price at the moment.
      </p>
    </div>
    <div v-else class="mx-20 my-40 text-center">
      <v-progress-linear
        color="orange darken-2"
        indeterminate
        height="6"
      ></v-progress-linear>
    </div>
    <Dialog :dialog="savePriceDialog" :dialog-width="1120" dense>
      <template v-slot:title>
        <v-layout>
          <span>Manage Price</span>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text mr-2"
            depressed
            color="cyan"
            tile
            :disabled="isDisable"
            @click="addPrice()"
          >
            <v-icon left>mdi-plus</v-icon>
            Add New Price
          </v-btn>
        </v-layout>
      </template>
      <template v-slot:body>
        <div class="px-4 pt-4 pb-3">
          <v-form
            ref="pricingForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="savePrice"
          >
            <v-col md="12" v-if="priceList && priceList.length">
              <v-row>
                <!-- <v-col class="py-0">
									<label for="fw-500" class="btx-label">Unit Price</label>
								</v-col> -->
                <v-col class="py-0">
                  <label class="btx-label">Selling Price/Unit Price</label>
                </v-col>
                <!--   <v-col class="py-0">
                  <label class="btx-label">Discounted Price</label>
                </v-col> -->
                <v-col class="py-0">
                  <label class="btx-label">Start Date</label>
                </v-col>
                <v-col class="py-0">
                  <label class="btx-label">End Date</label>
                </v-col>
                <v-col md="1" class="py-0"> </v-col>
              </v-row>
              <v-row
                v-for="(row, index) in priceList"
                :key="index"
                class="mb-3"
              >
                <v-col class="py-0">
                  <v-text-field
                    type="number"
                    solo
                    flat
                    dense
                    :disabled="pageLoading || row.id ? true : false"
                    :loading="pageLoading"
                    placeholder="Selling Price/Unit Price"
                    prepend-inner-icon="mdi-currency-usd"
                    @keyup="discountPriceChange(index)"
                    v-model="row.sale_price"
                    :rules="[
                      validateRules.required(
                        row.sale_price,
                        'Selling Price/Unit Price'
                      ),
                    ]"
                    class="mt-0"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" v-if="false">
                  <v-text-field
                    type="number"
                    hide-details
                    dense
                    solo
                    flat
                    :disabled="pageLoading || row.id ? true : false"
                    :loading="pageLoading"
                    placeholder="Discount Price"
                    @keyup="discountPriceChange(index)"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model.number="row.discount_price"
                    class="mt-0"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <DatePicker
                    hide-details
                    hide-top-margin
                    :disabled="
                      isStartDateDisable(index) || pageLoading || row.id
                        ? true
                        : false
                    "
                    :loading="pageLoading"
                    placeholder="Start Date"
                    :min-date="end_min_date(row.start_date, index)"
                    v-model="row.start_date"
                    :rules="[
                      validateRules.required(row.start_date, 'Start Date'),
                    ]"
                  >
                  </DatePicker>
                </v-col>
                <v-col class="py-0">
                  <DatePicker
                    hide-details
                    readonly
                    :loading="pageLoading"
                    :disabled="true"
                    hide-top-margin
                    placeholder="End Date"
                    v-model="row.end_date"
                  >
                  </DatePicker>
                </v-col>
                <!-- <v-col class="py-0">
									<PriceInput
										type="number"
										hide-details
										:disabled="pageLoading"
										:readonly="row.id"
										:loading="pageLoading"
										placeholder="Unit Price"
										prepend-inner-icon="mdi-currency-usd"
										v-model="row.unit_price"
										class="mt-0"
										:rules="[vrules.required(row.unit_price, 'unit price')]"
										:class="{
											required: !row.unit_price,
										}"
									></PriceInput>
								</v-col> -->
                <!-- <v-col class="py-0">
									<PriceInput
										type="number"
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:readonly="row.id"
										placeholder="Selling Price/Unit Price"
										prepend-inner-icon="mdi-currency-usd"
										v-model="row.selling_price"
										:rules="[vrules.required(row.selling_price, 'Selling Price/Unit Price')]"
										:class="{
											required: !row.selling_price,
										}"
										class="mt-0"
									></PriceInput>
								</v-col> -->
                <!-- <v-col class="py-0">
									<PriceInput
										type="number"
										hide-details
										:disabled="pageLoading"
										:readonly="row.id"
										:loading="pageLoading"
										placeholder="Discount Price"
										prepend-inner-icon="mdi-currency-usd"
										@keyup="discount_price_change(index)"
										v-model="row.discount_price"
										class="mt-0"
									></PriceInput>
								</v-col> -->
                <!-- <v-col class="py-0">
									<DatePicker
										hide-details
										hide-top-margin
										:min-date="end_min_date(row.start_date, index)"
										:disabled="isStartDateDisable(index) || pageLoading || row.id"
										:loading="pageLoading"
										placeholder="Start Date"
										v-model="row.start_date"
										:rules="[vrules.required(row.start_date, 'Start Date')]"
										:class="{
											required: !row.start_date,
										}"
										@change="updateStartDate(row.start_date, index)"
									></DatePicker>
								</v-col> -->
                <!-- <v-col class="py-0">
									<DatePicker
										hide-details
										hide-top-margin
										:disabled="true"
										:min-date="row.start_date"
										:loading="pageLoading"
										placeholder="End Date"
										v-model="row.end_date"
									></DatePicker>
								</v-col> -->
                <v-col md="1" class="py-0">
                  <v-btn
                    v-if="priceList.length == index + 1"
                    class="white--text"
                    depressed
                    color="red darken-4"
                    tile
                    @click="removePrice(index, row)"
                    min-width="30"
                    max-width="30"
                    width="30"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </div>
      </template>
      <template v-slot:action>
        <v-btn color="mr-2" tile depressed @click="closeDialog()">Close</v-btn>
        <v-btn
          color="cyan white--text"
          tile
          depressed
          :loading="pageLoading"
          :disabled="pageLoading"
          @click="savePrice()"
          >Save</v-btn
        >
      </template>
    </Dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
//import ShowPrice from "@/view/components/ShowPrice";
//import DatePicker from "@/view/components/DatePicker2";
//import PriceInput from "@/view/components/PriceInput";
import Dialog from "@/view/components/Dialog";
import { cloneDeep } from "lodash";
import DatePicker from "@/view/components/DatePicker";
import moment from "moment";
// import { SET_MESSAGE } from "@/core/services/store/common.module";
// import ImageTemplate from "@/view/components/Image";

import {
  ErrorEventBus,
  SuccessEventBus,
  InitializeError,
} from "@/core/lib/message.lib";

export default {
  name: "ProductPriceListing",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    typeUuid: {
      type: String,
      default: null,
    },
    priceData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    productStatus: {
      type: Number,
      default: null,
    },
    product: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      prices: [],
      formValid: true,
      priceList: [],
      savePriceDialog: false,
      pageLoading: false,
    };
  },
  watch: {
    priceData(param) {
      this.prices = cloneDeep(param);
      this.priceList = cloneDeep(param);
    },
    savePriceDialog(param) {
      if (param) {
        this.priceList = cloneDeep(this.priceData);
      }
    },
  },
  methods: {
    discountPriceChange(index) {
      if (
        this.priceList[index].discount_price > this.priceList[index].sale_price
      ) {
        this.priceList[index].discount_price = this.priceList[index].sale_price;
      }
    },

    discount_price_change(index) {
      if (
        this.priceList[index].discount_price > this.priceList[index].sale_price
      ) {
        this.priceList[index].discount_price = this.priceList[index].sale_price;
      }
    },

    getPrices() {
      this.prices = this.priceData;
      this.priceList = cloneDeep(this.priceData);
      // ApiService.get(`${this.type}/${this.typeUuid}/suppliers`)
      // 	.then(({ data }) => {
      // 		this.prices = data.pricing_logs;
      // 	})
      // 	.catch((error) => {
      // 		this.$emit("error", error);
      // 	})
      // 	.finally(() => {
      // 		this.pageLoading = false;
      // 	});
    },
    savePrice() {
      // const _this = this;
      /* const formErrors = _this.validateForm(_this.$refs.pricingForm);
      if (formErrors.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
        return false;
      }

      if (!_this.$refs.pricingForm.validate()) {
        return false;
      }
       */
      this.$refs["pricingForm"].validate();
      const formErrors = this.validateForm(this.$refs["pricingForm"]);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      this.pageLoading = true;
      ApiService.post(`product/${this.product}/price-logs`, {
        price_log: this.priceList,
      })
        .then(() => {
          // _this.$store.commit(SET_MESSAGE, [
          //   { model: true, message: "Success ! Price has been updated." },
          // ]);
          SuccessEventBus.$emit(
            "update:success",
            "Success ! Price has been updated."
          );
          this.$emit("success", true);
          this.closeDialog();
        })
        .catch((error) => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    closeDialog() {
      this.savePriceDialog = false;
    },
    updateStartDate(/* date, index */) {
      // if (this.priceList.length > index + 1) {
      // 	this.$nextTick(() => {
      // 		this.priceList[index + 1].start_date = date;
      // 	});
      // }
    },
    addPrice() {
      let previous_end_date = null; // moment().format("YYYY-MM-DD");
      if (this.priceList.length < 1) {
        previous_end_date = moment().format("YYYY-MM-DD");
      }
      this.priceList.push({
        id: null,
        unit_price: null,
        sale_price: null,
        discount_price: null,
        start_date: previous_end_date,
        end_date: null,
      });
    },
    removePrice(index) {
      if (index >= 1) {
        this.priceList[index - 1].end_date = null;
      }
      this.priceList.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["errors"]),
    end_min_date: () => {
      return function (date, index) {
        let _priceList = cloneDeep(this.priceList);
        if (index > 0) {
          const min_end_date = _priceList[index - 1].start_date;
          this.priceList[index - 1].end_date = date;
          return min_end_date;
        }
        return moment().format("YYYY-MM-DD");
      };
    },
    isDisable() {
      const exists = this.priceList.find((row) => {
        return !row.start_date;
      });
      return exists ? true : false;
    },
    isStartDateDisable: () => {
      return function (index) {
        if (this.priceList.length == index + 1) {
          return false;
        }
        return true;
      };
    },
  },
  components: {
    //ShowPrice,
    //DatePicker,
    //	PriceInput,
    Dialog,
    DatePicker,
  },
  mounted() {
    this.getPrices();
  },
};
</script>
