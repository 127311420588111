<template>
  <div class="internal-parts-listing">
    <v-layout class="p-2 pr-0 border-bottom-light-grey min-height-57px">
      <v-flex class="d-flex font-level-3-bold my-auto">
        <span class="form-title font-size-20 font-weight-700"> Available </span>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-simple-table fixed-header height="67vh">
      <template v-slot:default>
        <thead>
          <tr>
            <!-- <th width="50" class="simple-table-th">#</th> -->
            <th class="simple-table-th">Serial Number</th>
            <th class="simple-table-th">Inbond Date</th>
            <th class="simple-table-th">Inbond Doc #</th>
            <th class="simple-table-th">Outbond Date #</th>
            <th class="simple-table-th">Outbond #</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="inventoryData.length > 0">
            <tr
              v-for="(row, index) in inventoryData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <!-- <td class="simple-table-td">
                {{ index + 1 }}
              </td> -->
              <td class="simple-table-td pl-4">
                <template v-if="row.text">
                  <showBarcode
                    :barcodeUrl="row.barcode_image"
                    :barcode-text="row.text"
                    title="Barcode"
                  />
                  <span class="mr-1 text-capitalize">{{ row.text }}</span>
                </template>

                <template v-else>
                  <em class="text-muted">no Serial no.</em>
                </template>
              </td>
              <td class="simple-table-td pl-4">
                <template v-if="inbondDate">
                  {{ formatDate(inbondDate) }}
                </template>
                <template v-else>
                  <em class="text-muted">no inbond date</em>
                </template>
              </td>
              <td class="simple-table-td pl-4 text-capitalize">
                <template v-if="inbondDoc">
                  {{ inbondDoc }}
                </template>
                <template v-else>
                  <em class="text-muted">no inbond doc</em>
                </template>
              </td>
              <td class="simple-table-td pl-4 text-capitalize">
                <em class="text-muted">no outbond date</em>
                <!-- <template v-if="inbondDoc">
                  {{ inbondDoc }}
                </template>
                <template v-else>
                  <em class="text-muted">no outbond date</em>
                </template> -->
              </td>
              <td class="simple-table-td pl-4">
                <template v-if="row.transaction_no">
                  {{ formatDate(row.transaction_no) }}
                </template>
                <template v-else>
                  <em class="text-muted">no outbond no</em>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-if="pageLoading">
              <tr v-for="row in 3" :key="row">
                <!-- <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td> -->
                <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no price at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <ListingFooter
      :dataLoading="pageLoading"
      :currentPage="current_page"
      :totalPages="total_pages"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="total_rows"
      removePage
    ></ListingFooter>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import showBarcode from "@/view/components/showBarcode.vue";
// import moment from "moment-timezone";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      dates: [],
      pageLoading: false,
      inventoryData: new Array(),
      filter: {
        search: null,
      },
      per_page: 15,
      current_page: 1,
      offset: 0,
      total_pages: 0,
      total_rows: 0,
    };
  },
  components: {
    ListingFooter,
    showBarcode,
  },
  props: {
    type: {
      required: true,
      type: String,
      default: "",
    },
    type_id: {
      required: true,
      type: Number,
      default: 0,
    },
    inbondDate: {
      type: String,
      default: null,
    },
    inbondDoc: {
      type: String,
      default: null,
    },
  },
  watch: {
    type_id() {
      let type_id = this.lodash.toSafeInteger(this.type_id);
      if (type_id > 0) {
        this.getProductInventory(type_id);
      }
    },
  },
  methods: {
    getProductInventory(productId) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: `all-serial-number/${this.type}/${productId}`,
          data: {
            //type: _this.type,
            per_page: _this.per_page,
            current_page: _this.current_page,
          },
        })
        .then((data) => {
          this.inventoryData = data.data.rows;
          _this.offset = data.data.offset;
          _this.total_pages = Math.ceil(
            data.data.total_rows / data.data.per_page
          );
          _this.current_page = data.data.current_page;
          _this.total_rows = data.data.total_rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    PaginationEventBus.$off("update:pagination");
  },
  mounted() {
    const _this = this;
    let type_id = _this.lodash.toSafeInteger(_this.type_id);
    if (type_id > 0) {
      _this.getProductInventory(type_id);
    }

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.current_page = param;
      _this.getProductInventory(_this.type_id);
    });
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.inventoryData) === false
        ? this.offset + 1
        : 0;
    },
    showingTo() {
      return this.offset + (this.inventoryData ? this.inventoryData.length : 0);
    },
  },
};
</script>
